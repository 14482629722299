.Faq {
  background: #f3f6f1;
  padding: 9% 6%;
}

.Row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 3.125vw;
}

.Column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.H2 {
  font-style: normal;
  font-weight: bold;
  font-size: 4.063vw;
  line-height: 4.688vw;
  letter-spacing: -1.386px;
  color: #11172c;
  width: 100%;
}

.P {
  font-style: normal;
  font-weight: normal;
  font-size: 1.406vw;
  line-height: 2.344vw;
  letter-spacing: -0.594px;
  color: #0a122b;
  padding-left: 2.813vw;
}

.H5 {
  font-style: normal;
  font-weight: bold;
  font-size: 1.406vw;
  line-height: 2.188vw;
  letter-spacing: -0.66px;
  color: #11172c;
}

.H6 {
  font-style: normal;
  font-weight: normal;
  font-size: 1.25vw;
  line-height: 2.188vw;
  letter-spacing: -0.528px;
  color: #0a122b;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
  background-color: transparent !important;
}

.Mb {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

@media (max-width: 500px) {
  .Mb {
    grid-template-columns: 1fr;
  }

  .H2 {
    font-weight: 800;
    font-size: 40px;
    line-height: 45px;
    letter-spacing: -1.386px;
    overflow-y: hidden;
  }

  .P {
    font-size: 13px;
    line-height: 30px;
    letter-spacing: -0.594px;
    padding-left: 0;
    padding-top: 36px;
  }

  .Row {
    flex-direction: column;
    padding-top: 20px;
  }

  .Faq {
    padding: 15% 6% 24%;
  }

  .H5 {
    font-size: 15px;
    line-height: 22px;
  }

  .H6 {
    font-size: 13px;
    line-height: 28px;
  }
}
