.Cibil {
  padding: 1% 0% 0% 6%;
  /* background-image: url("https://firebasestorage.googleapis.com/v0/b/vintage-fin.appspot.com/o/images%2Fsection-1%2FGroup%2015115-min.png?alt=media&token=e9f6b654-1f62-4458-a4ea-41807bf191df"); */

  background-image: url("https://firebasestorage.googleapis.com/v0/b/vintage-fin.appspot.com/o/images%2Fsection-5%2Fbg%2Fbackground.svg?alt=media&token=5eaa14ff-f4ca-4206-a9dc-b49a00256450");
  background-size: cover;
}

.Row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 6.25vw;
}

.Column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.PersonalLoan {
  width: 43.984vw;
  height: 43.984vw;
}

.DoubleColumn {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 2;
}

.H1 {
  font-style: normal;
  font-weight: bold;
  font-size: 4.922vw;
  line-height: 6.094vw;
  letter-spacing: -0.02em;
  color: #010d23;
}

.P {
  font-style: normal;
  font-weight: normal;
  font-size: 1.563vw;
  line-height: 2.891vw;
  color: #010d23;
  margin-top: 3.125vw;
  margin-bottom: 2.344vw;
}

.Input {
  background: #ffffff;
  backdrop-filter: blur(24px);
  width: 18.594vw;
  height: 3.594vw;
  border: none;
  padding: 1.172vw;
  font-style: normal;
  font-weight: normal;
  font-size: 1.094vw;
  line-height: 1.641vw;
  color: #000000;
}

.Btn {
  background: #21201f;
  backdrop-filter: blur(50px);
  width: 15.391vw;
  height: 3.594vw;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 1.094vw;
  line-height: 1.641vw;
  color: #ffffff;
  cursor: pointer;
}

.inputFeedback {
  color: red;
  margin-top: 0.25rem;
  margin-left: 10px;
  font-size: 12px;
}

@media (max-width: 500px) {
  .Cibil {
    background-image: url("https://firebasestorage.googleapis.com/v0/b/vintage-fin.appspot.com/o/images%2Fsection-5%2Fbg%2Fbackground.svg?alt=media&token=5eaa14ff-f4ca-4206-a9dc-b49a00256450");
  }
  .DoubleColumn {
    flex: 1;
  }

  .H1 {
    font-size: 42px;
    line-height: 50px;
    letter-spacing: -0.02em;
    overflow-y: hidden;
    font-weight: 800;
    width: 82%;
  }

  .Row {
    flex-direction: column;
    flex-wrap: nowrap;
  }
  .Br {
    display: none;
  }

  .P {
    font-size: 13px;
    line-height: 21px;
    width: 60%;
  }

  .Input {
    width: 178px;
    height: 42px;
    font-size: 13px;
    line-height: 19px;
  }

  .Btn {
    width: 147px;
    height: 42px;
    font-size: 13px;
    line-height: 19px;
  }

  .PersonalLoan {
    width: 326px;
    height: 340px;
    margin-top: 51px;
  }
  .Row {
    padding-top: 45px;
  }
}
