.inputs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.563vw;
}

.inputs input,
.inputs select {
  display: block;
  width: 100%;
  padding: 0.938vw 1.25vw;
  font-size: 1.094vw;
  font-weight: 400;
  line-height: 1.6;
  color: #151e28;
  background-color: #fff;
  border-radius: 0.391vw;
  outline: none;
  border: 1px solid #dee2e6;
}

.privacy {
  display: grid;
  grid-template-columns: 20px 1fr;
  align-items: center;
  gap: 0.625vw;
  margin-top: 1.563vw;
  margin-bottom: 1.563vw;
}

.privacy svg path {
  fill: #ccc;
}

.privacy p {
  font-weight: 400;
  font-size: 1.25vw;
  line-height: 1.875vw;
  color: #ddd;
}

.btn {
  width: 100%;
  height: 3.906vw;
  color: #fff;
  background-color: #624bff;
  font-weight: 600;
  line-height: 1.6;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.094vw;
  border-radius: 0.391vw;
  border: none;
}

.btn:hover {
  opacity: 0.8;
}

@media (max-width: 500px) {
  .inputs {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .privacy p {
    font-size: 10px !important;
    line-height: 15px !important;
  }

  .inputs input,
  .inputs select {
    font-size: 14px;
    line-height: 20px;
    padding: 12px 16px;
    border-radius: 5px;
  }

  .privacy {
    margin-top: 20px;
    margin-bottom: 20px;
    gap: 8px;
  }

  .btn {
    height: 46px;
    font-size: 14px;
    line-height: 20px;
    border-radius: 5px;
  }
}
