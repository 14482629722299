.NumbersBg {
  /* background: url("https://firebasestorage.googleapis.com/v0/b/vintage-fin.appspot.com/o/images%2Fsection-1%2FGroup%2015115-min.png?alt=media&token=e9f6b654-1f62-4458-a4ea-41807bf191df"); */
  background: url("https://firebasestorage.googleapis.com/v0/b/vintage-fin.appspot.com/o/images%2Fsection-5%2Fbg%2Fbackground.svg?alt=media&token=5eaa14ff-f4ca-4206-a9dc-b49a00256450");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 6% 6%;
}

.NumData {
  border-top: 1px solid #000000;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.H4 {
  font-style: normal;
  font-weight: 600;
  font-size: 4.219vw;
  line-height: 4.688vw;
  color: #000000;
  margin-bottom: 60px;
}

.H3 {
  font-style: normal;
  font-weight: normal;
  font-size: 10.938vw;
  line-height: 14.922vw;
  color: #000000;
}

.H5 {
  font-style: normal;
  font-weight: 600;
  font-size: 2.422vw;
  line-height: 3.75vw;
  text-align: right;
  color: #000000;
}

.P {
  font-style: normal;
  font-weight: 600;
  font-size: 13.314px;
  line-height: 1.953vw;
  text-align: right;
  color: #000000;
}

.Btn {
  width: 21.719vw;
  height: 3.984vw;
  background: #b9f59d;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 1.078vw;
  line-height: 1.641vw;
  color: #0b1642;
  cursor: pointer;
  margin-top: 1.172vw;
}

.NumData span {
  font-size: 5vw;
}

@media (max-width: 500px) {
  .NumbersBg {
    padding: 15% 6%;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/vintage-fin.appspot.com/o/images%2Fsection-5%2Fbg%2Fbackground.svg?alt=media&token=5eaa14ff-f4ca-4206-a9dc-b49a00256450");
  }

  .NumData span {
    font-size: 27px;
  }

  .H4 {
    font-size: 40px;
    line-height: 50px;
    overflow-y: hidden;
  }

  .BrMb {
    display: none;
  }

  .H3 {
    font-size: 65px;
    line-height: 89px;
    font-weight: 700;
  }

  .NumData {
    flex-direction: column;
    align-items: flex-start;
    padding: 60px 0px;
    border-top: 0.5px solid rgb(11, 22, 66, 0.5);
  }

  .H5 {
    text-align: left;
    font-size: 22px;
    line-height: 31px;
  }

  .P {
    text-align: inherit;
    line-height: 20px;
  }

  .DivMb {
    margin: auto;
  }

  .DivMb h2 {
    text-align: center !important;
    font-weight: 600;
    font-size: 40px;
    line-height: 45px !important;
    overflow-y: hidden;
  }

  .Btn {
    width: 278px;
    height: 51px;
    font-size: 13.8564px;
    line-height: 21px;
    margin-top: 30px;
  }
}
