.About {
  padding: 6% 6%;
}

.Img {
  width: 100%;
  height: 50.313vw;
  object-fit: contain;
}

.H1 {
  font-style: normal;
  font-weight: 800;
  font-size: 6.094vw;
  line-height: 7.109vw;
  text-align: center;
  letter-spacing: -0.03em;
  color: #0b1642;
}

.H6 {
  font-style: normal;
  font-weight: 500;
  font-size: 2.031vw;
  line-height: 2.813vw;
  text-align: center;
  letter-spacing: -0.05em;
  color: #0b1642;
  opacity: 0.8;
  margin-top: 1.563vw;
  margin-bottom: 3.906vw;
}

.H5 {
  font-style: normal;
  font-weight: bold;
  font-size: 2.969vw;
  line-height: 4.297vw;
  text-align: center;
  color: #0b1642;
  margin-top: 3.906vw;
  margin-bottom: 4.063vw;
}

.P {
  font-style: normal;
  font-weight: normal;
  font-size: 1.563vw;
  line-height: 2.656vw;
  text-align: center;
  color: #0b1642;
  opacity: 0.6;
}

.BtnDiv {
  display: flex;
  justify-content: center;
  margin-top: 4.219vw;
}

@media (max-width: 500px) {
  .About {
    padding: 18% 6%;
  }

  .H1 {
    font-size: 48px;
    line-height: 90px;
  }

  .H6 {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: -0.05em;
  }

  .BrMb {
    display: none;
  }

  .H5 {
    font-size: 30px;
    line-height: 40px;
    overflow-y: hidden;
  }

  .BtnDiv {
    flex-direction: column;
    height: 125px;
    justify-content: space-between;
    align-items: center;
  }

  .P {
    font-size: 20px;
    line-height: 34px;
  }
}
