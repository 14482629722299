.fixedButton {
  background: rgba(3, 9, 36, 0.8);
  backdrop-filter: blur(4px);
  position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 10;
  display: flex;
  align-items: center;
  width: 100%;
  height: 72px;
}
.fixedButton a {
  text-decoration: none;
}

.fixedButton div {
  width: 149px;
  height: 48px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 14px;
  margin: auto;
}

.Btn1 {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #0b1642;
}

.Btn2 {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #1e1e1e;
}

@media (min-width: 501px) {
  .fixedButton {
    display: none;
  }
}
