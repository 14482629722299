@media screen and (min-width: 1200px) {
  .secondaryParentItem {
    display: flex;
    justify-content: center;
    padding: 20px;
    width: 300px;
    height: 130px;
    margin: auto;
  }

  .basicClass {
    background-image: linear-gradient(45deg, black, transparent);
    position: fixed;
    z-index: 500;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
  }
}

@media screen and (min-width: 300px) and (max-width: 1199px) {
  .secondaryParentItem {
    display: flex;
    justify-content: center;
    padding: 20px;
    width: 300px;
    height: 130px;
    margin: auto;
  }

  .basicClass {
    background-image: linear-gradient(45deg, black, transparent);
    position: fixed;
    z-index: 500;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
  }
}
