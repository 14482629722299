.container {
  padding: 120px 6%;
  background: linear-gradient(
    135deg,
    rgba(31, 43, 112, 0.9),
    rgba(31, 43, 112, 0.9) 47%,
    rgba(148, 58, 158, 0.9)
  );
}

.heading {
  text-align: center;
  margin-bottom: 6.25vw;
}

.heading h2 {
  font-weight: 700;
  font-size: 3.125vw;
  line-height: 3.75vw;
  color: #fff;
  margin-bottom: 0.781vw;
}

.heading h3 {
  font-weight: 400;
  font-size: 1.875vw;
  line-height: 2.813vw;
  color: #aaa;
}

@media (max-width: 500px) {
  .container {
    padding: 60px 6%;
  }

  .heading {
    margin-bottom: 50px;
  }

  .heading h2 {
    font-size: 30px;
    line-height: 40px;
  }

  .heading h3 {
    font-size: 18px;
    line-height: 28px;
  }
}
