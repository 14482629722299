.container {
  padding: 120px 6%;
}

.heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 4.219vw;
}

.heading h3 {
  font-weight: 700;
  font-size: 2.5vw;
  line-height: 3.125vw;
  letter-spacing: -0.5px;
  color: #141416;
}

.heading p {
  font-weight: 400;
  font-size: 1.563vw;
  margin-top: 0.625vw;
  color: #7d7d7d;
}

.list {
  list-style: none;
  margin-bottom: 1.25vw;
}

.list li {
  display: grid;
  grid-template-columns: 25px 1fr;
  align-items: center;
  font-size: 1.25vw;
  font-weight: 400;
  line-height: 1.875vw;
  color: #7d7d7d;
}

.list li svg {
  margin-right: 7px;
  width: 16px;
  height: 16px;
}

.footer p {
  font-weight: 400;
  font-size: 1.563vw;
  line-height: 2.188vw;
  color: #7d7d7d;
  margin-bottom: 1.25vw;
}

.footer b {
  font-weight: 800;
  font-size: 1.25vw;
  line-height: 1.875vw;
}

@media (max-width: 500px) {
  .container {
    padding: 70px 6%;
  }

  .heading {
    text-align: center;
    margin-bottom: 50px;
  }

  .heading h3 {
    font-size: 24px;
    line-height: 32px;
  }

  .heading p {
    font-size: 18px;
    line-height: 26px;
    max-width: 90%;
  }

  .list li {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 8px;
    display: block;
  }

  .list li svg {
    margin-bottom: -3px;
  }

  .footer p {
    font-size: 14px;
    line-height: 20px;
  }
  .footer b {
    font-size: 14px;
    line-height: 20px;
  }
}
