.container {
  padding: 120px 6%;
}

.cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.card {
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 20, 0.08), 0 1px 2px rgba(0, 0, 20, 0.08);
  padding: 1.875vw;
  max-width: 45.313vw;
  margin: 0 15px;
}

.card h4 {
  font-weight: 700;
  font-size: 1.719vw;
  line-height: 2.344vw;
  color: #151e28;
  margin-bottom: 1.25vw;
}

.list {
  list-style: none;
}

.card li {
  font-weight: 400;
  font-size: 1.406vw;
  line-height: 2.188vw;
  margin-bottom: 8px;
}

.card li svg {
  width: 18px;
  height: 18px;
  margin-bottom: -2px;
  margin-right: 8px;
}

.card li span {
  font-weight: 800;
  color: #7d7d7d;
}

@media (max-width: 500px) {
  .container {
    padding: 50px 6%;
  }

  .cards {
    grid-template-columns: 1fr;
  }

  .card {
    margin: 0;
    margin-bottom: 40px;
    max-width: 100%;
    padding: 20px;
    width: 100%;
    border: 1px solid rgba(0, 0, 20, 0.08);
    box-shadow: none;
  }

  .card h4 {
    font-size: 20px;
    line-height: 28px;
  }

  .list li {
    font-size: 13px;
    line-height: 20px;
  }

  .list li svg {
    width: 16px;
    height: 16px;
  }
}
