.Header {
  background: #141416;
  height: 6.25vw;
  padding: 1% 6%;
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 10;
}

@media (max-width: 500px) {
  .Header {
    /* flex-direction: column;
    align-items: flex-start;
    height: 100%; */
    height: 80px;
  }
}
